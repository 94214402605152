footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
}

footer div {
  margin-right: 1em;
}
